/* Primary Biru = #e5f3ed */
/* Font white = #ffffff */


.App{
  font-size: 14px;
  background-color: #ffffff;
  color:#616161;
  font-size: 14px;
}

html{
  scroll-behavior: smooth;
  
}

.navbar-top{
  background-color:#FFFFFF !important;
  box-shadow: 0px -8px 20px 0px black;
}

.navbar-brand{
  font-size: 14px;
  
}

.menu-top{
  color: #616161 !important;
  border: 1px solid#ffffff;
}

.menu-top:hover{
  border-bottom: 1px solid #616161;
  transition: 350ms ease-in-out;

}
.navbar-dark .navbar-toggler-icon{
  background-image: ('./assets/favicon.ico') !important;
}

.text-registration {
  color: #f57600
}

.btn-registration {
  background-color: #f57600 !important;
  border: 1px solid #f57600;
}

.btn-registration:hover,.btn-registration:active,.btn-registration:focus {
  background-color: #f57600 !important;
  border: 1px solid #f57600;
}

.btn-outline-primary{
  color:#4a88cf !important;
  border: 1px solid #4a88cf;
}
.btn-outline-primary:hover,.btn-outline-primary:active{
  background-color: #4a88cf !important;
  border: 1px solid #4a88cf;
  color: #ffffff !important;
  transition: 150ms ease-in-out;
}

.tab-agenda{
  color:#4a88cf !important;
  border: 1px solid red;
  cursor: pointer;
}
.tab-agenda-active{
  background-color: #4a88cf !important;
  color: #ffffff !important;
  transition: 350ms ease-in-out;
}
.agenda-container{
  background-color: #fafafa;
}

iframe{
  box-shadow: 1px 1px 5px 0px black;
}

.Page{
  animation: Open 1s ease-in-out;
}

@keyframes Open{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

p{
  line-height: 2.5
}
.dropdown-item:hover{
  background-color: white !important;
  color: #343A40 !important;
}

.hotel-link{
  text-decoration: none !important;
  color: black !important;
}

.hotel-link{
  box-shadow: 3px 3px 10px 0px #9E9E9E;
  transition: 0.3s ease-out;
}
.img-hotels{
  box-shadow: 3px 3px 10px 0px #9E9E9E;
}



.cfpshow{
  opacity: 0;
  display: block;
  animation-name: cfpshow;
  animation-duration: 800ms;
  animation-delay: 350ms;
  animation-timing-function:cubic-bezier(0.57, 0.21, 1, 1);
  animation-fill-mode: forwards;
}

.cfphidden{
  display: none;
}

@keyframes cfpshow{
  0%{
    display: block;
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    display: none;
    opacity: 1;

  }
}

.cfptitle{
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.cfptitle:hover > h5 > i{
  color: #dc3545;
  text-shadow: 1px 1px 1px #616161;
  transition: 200ms ease-out;

}

.fa-minus{
  color: #dc3545;
  text-shadow: 1px 1px 1px #616161;
 
}

.btn-rounded{
  border-radius: 4vh;
}

.link-back{
  height: 8vh;
}
.back-link{
  text-decoration: none !important;
  color: #C82333;
  font-size: 12px;
  animation-name: linkback;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes linkback{
  from{
    color:red;
  }
  to{
    color:#616161;
    
  }
}

.sponsor-title{
  background-color: #4a88cf;
  color: #fff;
}

.registration-banner{
    color: #333;
    background-color: #ffefe2;
    border-color: white;
}
